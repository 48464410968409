<template>
  <el-container class="home-container">
    <!-- 左侧边栏区域 -->
    <el-aside :width="isCollapse ? '68px':'200px'"  translation="width:0.2s" class="hidden-xs-only">
      <!-- 侧边栏菜单区域 -->
      <div class="">
        <!--侧边栏菜单区域-->
        <!-- 判断是否隐藏侧边栏 -->
<!--        <img src="../../assets/logo.png" style="width: 40px ; margin:0 auto">-->
        <div class="topBox"></div>
<!--        <div class="toggle-button" @click="toggleCollapse" >|||</div>-->

<!--        -->
        <el-menu
            :collapse="isCollapse"
            background-color="#333744"
            text-color="#fff"
            active-text-color="#ffd04b"
            unique-opened
            router
            default-active="id"
            :collapse-transition="false"

        >
          <!-- 移动版-->
<!--          <el-menu-item index="/mBlow" id="10">-->
<!--            <i class="el-icon-mobile-phone"></i>-->
<!--            <span slot="title">移动端</span>-->
<!--          </el-menu-item>-->

          <!--首页-->
          <el-menu-item index="/welcome" id="1">
            <i class="el-icon-menu"></i>
            <span slot="title">首页</span>
          </el-menu-item>

          <!--财务管理-->
          <el-submenu index="2" v-has="''">
            <!--   一级菜单模板区域   -->
            <template slot="title">
              <!--    图标    -->
              <i class="el-icon-coin"></i>
              <!--     文本  -->
              <span>财务管理</span>
            </template>
            <!--    二级菜单  -->
            <el-menu-item index="/Withdraw" id="1" v-has="'xxx'">
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-menu"></i>
                <!--     文本  -->
                <span>提现</span>
              </template>
            </el-menu-item>
            <!--   二级菜单  -->
            <el-menu-item index="/Withdraw" id="2" v-has="'xxx'" >
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-menu"></i>
                <!--     文本  -->
                <span>提现记录</span>
              </template>
            </el-menu-item>
            <!--   二级菜单  -->
            <el-menu-item index="/Withdraw" id="3" v-has="'xxx'">
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-menu"></i>
                <!--     文本  -->
                <span>提现管理</span>
              </template>
            </el-menu-item>

<!--            <el-menu-item index="/project" id="4" >-->
<!--              <template slot="title">-->
<!--                &lt;!&ndash;    图标    &ndash;&gt;-->
<!--                <i class="el-icon-menu"></i>-->
<!--                &lt;!&ndash;     文本  &ndash;&gt;-->
<!--                <span>项目管理</span>-->
<!--              </template>-->
<!--            </el-menu-item>-->

          </el-submenu>

          <!--平台管理-->
          <el-submenu index="3" v-has="''">
            <!--   一级菜单模板区域   -->
            <template slot="title">
              <!--    图标    -->
              <i class="el-icon-c-scale-to-original"></i>
              <!--     文本  -->
              <span>平台管理</span>
            </template>


            <!--   二级菜单  -->
            <el-menu-item index="/platform" id="2" v-has="'GET_PingtaiPermission'">
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-menu"></i>
                <!--     文本  -->
                <span>平台管理</span>
              </template>
            </el-menu-item>

            <el-menu-item index="/project" id="3" v-has="'GET_XiangmuPermission'">
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-menu"></i>
                <!--     文本  -->
                <span>项目管理</span>
              </template>
            </el-menu-item>

            <!--   二级菜单  -->
            <el-menu-item index="/roles" id="4" v-has="'GET_UserPermission'">
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-menu"></i>
                <!--     文本  -->
                <span>角色管理</span>
              </template>
            </el-menu-item>

          </el-submenu>

          <!--用户管理-->
          <el-submenu index="4" v-has="''">
            <!--   一级菜单模板区域   -->
            <template slot="title">
              <!--    图标    -->
              <i class="el-icon-user-solid"></i>
              <!--     文本  -->
              <span>用户管理</span>
            </template>

            <!--    二级菜单  -->
            <el-menu-item index="/AdminUser" id="1" v-has="'GET_UserPermission'">
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-menu"></i>
                <!--     文本  -->
                <span>管理员管理</span>
              </template>
            </el-menu-item>
            <!--    二级菜单  -->
            <el-menu-item index="/user" id="4" v-has="'GET_UserPermission'">
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-menu"></i>
                <!--     文本  -->
                <span>客户管理</span>
              </template>
            </el-menu-item>

          </el-submenu>

          <!--订单管理-->
          <el-submenu index="5" v-has="''">
            <!--   一级菜单模板区域   -->
            <template slot="title">
              <!--    图标    -->
              <i class="el-icon-document"></i>
              <!--     文本  -->
              <span>订单管理</span>
            </template>
            <!--    二级菜单  -->
            <el-menu-item index="/paymentOrder" id="1" v-has="'GET_PayorderPermission'">
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-menu"></i>
                <!--     文本  -->
                <span>支付订单</span>
              </template>
            </el-menu-item>
            <!--    二级菜单  -->
            <el-menu-item index="/paymentRefund" id="3" v-has="'GET_PayorderPermission'">
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-menu"></i>
                <!--     文本  -->
                <span>支付退款</span>
              </template>
            </el-menu-item>


<!--            &lt;!&ndash;    二级菜单  &ndash;&gt;-->
<!--            <el-menu-item index="/rechargeOrder" id="2" v-has="'GET_ChongzhiOrderPermission'">-->
<!--              <template slot="title">-->
<!--                &lt;!&ndash;    图标    &ndash;&gt;-->
<!--                <i class="el-icon-menu"></i>-->
<!--                &lt;!&ndash;     文本  &ndash;&gt;-->
<!--                <span>充值订单</span>-->
<!--              </template>-->
<!--            </el-menu-item>-->
<!--            &lt;!&ndash;    二级菜单  &ndash;&gt;-->
<!--            <el-menu-item index="/rechargeRefund" id="4" v-has="'GET_ChongzhiOrderPermission'">-->
<!--              <template slot="title">-->
<!--                &lt;!&ndash;    图标    &ndash;&gt;-->
<!--                <i class="el-icon-menu"></i>-->
<!--                &lt;!&ndash;     文本  &ndash;&gt;-->
<!--                <span>充值退款</span>-->
<!--              </template>-->
<!--            </el-menu-item>-->

          </el-submenu>

          <!--活动管理-->
          <el-submenu index="6" v-has="''">
            <!--   一级菜单模板区域   -->
            <template slot="title">
              <!--    图标    -->
              <i class="el-icon-star-off"></i>
              <!--     文本  -->
              <span>活动管理</span>
            </template>
            <!--    二级菜单  -->
            <el-menu-item index="/activity" id="5" v-has="'GET_HuodongPermission'">
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-menu"></i>
                <!--     文本  -->
                <span>活动管理</span>
              </template>
            </el-menu-item>

          </el-submenu>

          <!--吹风机管理-->
          <el-submenu index="7" v-has="''" >
            <!--   一级菜单模板区域   -->
            <template slot="title">
              <!--    图标    -->
              <i class="el-icon-potato-strips"></i>
              <!--     文本  -->
              <span>吹风机管理</span>
            </template>
            <!--    二级菜单  -->
            <el-menu-item index="/blowing" id="3" v-has="'GET_ChuifengjiPermission'">
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-menu"></i>
                <!--     文本  -->
                <span>吹风机列表</span>
              </template>
            </el-menu-item>

            <!--    二级菜单  -->
            <el-menu-item index="/blowServe" id="4" v-has="'GET_ChuifengjifuwuPermission'">
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-menu"></i>
                <!--     文本  -->
                <span>吹风机服务</span>
              </template>
            </el-menu-item>


          </el-submenu>

          <!--充电桩管理-->
          <el-submenu index="8" v-has="''" >
            <!--   一级菜单模板区域   -->
            <template slot="title">
              <!--    图标    -->
              <i class="el-icon-potato-strips"></i>
              <!--     文本  -->
              <span>充电桩管理</span>
            </template>
            <!--    二级菜单  -->
            <el-menu-item index="/pilesServe" id="3" v-has="'GET_ChuifengjiPermission'">
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-menu"></i>
                <!--     文本  -->
                <span>充电桩列表</span>
              </template>
            </el-menu-item>

            <!--    二级菜单  -->
            <el-menu-item index="/pilesServe" id="4" v-has="'GET_ChuifengjifuwuPermission'">
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-menu"></i>
                <!--     文本  -->
                <span>充电桩服务</span>
              </template>
            </el-menu-item>


          </el-submenu>


<!--          <el-submenu index="9" v-has="''">-->
<!--            &lt;!&ndash;   一级菜单模板区域   &ndash;&gt;-->
<!--            <template slot="title">-->
<!--              &lt;!&ndash;    图标    &ndash;&gt;-->
<!--              <i class="el-icon-money"></i>-->
<!--              &lt;!&ndash;     文本  &ndash;&gt;-->
<!--              <span>洗衣机管理</span>-->
<!--            </template>-->
<!--            &lt;!&ndash;    二级菜单  &ndash;&gt;-->
<!--            <el-menu-item index="/washing" id="3" v-has="'GET_UserPermission'">-->
<!--              <template slot="title">-->
<!--                &lt;!&ndash;    图标    &ndash;&gt;-->
<!--                <i class="el-icon-menu"></i>-->
<!--                &lt;!&ndash;     文本  &ndash;&gt;-->
<!--                <span>洗衣机列表</span>-->
<!--              </template>-->
<!--            </el-menu-item>-->

<!--            &lt;!&ndash;    二级菜单  &ndash;&gt;-->
<!--            <el-menu-item index="/washServe" id="4" v-has="'GET_UserPermission'">-->
<!--              <template slot="title">-->
<!--                &lt;!&ndash;    图标    &ndash;&gt;-->
<!--                <i class="el-icon-menu"></i>-->
<!--                &lt;!&ndash;     文本  &ndash;&gt;-->
<!--                <span>洗衣机服务</span>-->
<!--              </template>-->
<!--            </el-menu-item>-->
<!--          </el-submenu>-->





        </el-menu>

      </div>
    </el-aside>
    <!-- 页面主题区域 -->
    <el-container>
      <!-- 上头部区域 -->
      <el-header>


<!--        <el-button @click="drawer = true"  type="info" round style="margin-left: 16px;">-->
<!--          展开-->
<!--        </el-button>-->

        <div>

          <!--    项目下拉选择框   -->
          <el-select v-model="xmList.id" placeholder="请选择项目"    clearable @change="selectChange(xmList.id)">
            <el-option

                v-for="item in xmList"
                :key="item.id"
                :label="item.xiangmumingcheng"
                :value="item.id">
              <span style="float: left; color: #8492a6; font-size: 13px">{{ item.xiangmumingcheng }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.id }}</span>
            </el-option>
            <!--   项目分页功能   -->
            <el-pagination
                small
                @size-change="handleSizeChange1"
                @current-change="handleCurrentChange1"
                :current-page="this.newPage1"
                layout="prev, pager, next"
                :total="Total">
            </el-pagination>
          </el-select>




        </div>
        <div>

          <span>后台管理</span>

        </div>

        <div>
          <!-- 右侧用户下拉菜单 -->
          <el-dropdown @command="dropdown2">
               <span class="el-dropdown-link">
                <i class="el-icon-user-solid"></i>
                  {{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
               </span>
            <el-dropdown-menu slot="dropdown">
<!--              <el-dropdown-item command="switch">切换角色</el-dropdown-item>-->
<!--              <el-dropdown-item command="message">消息中心</el-dropdown-item>-->
              <el-dropdown-item command="revise">修改密码</el-dropdown-item>
              <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <el-button type="info" @click="logout">退出</el-button> -->
        </div>

      </el-header>
      <!-- 右主体区域 -->
      <el-main>
        <el-drawer
            title="菜单"
            background-color="#333744"
            :visible.sync="drawer"
            :direction="direction"
            size="45%"
        >

          <!--  导航栏  -->
          <el-menu
              :collapse="isCollapse"
              background-color="#333744"
              text-color="#fff"
              active-text-color="#ffd04b"
              unique-opened
              router
              default-active="id"
              :collapse-transition="false"

          >
            <!-- 移动版-->
            <!--          <el-menu-item index="/mBlow" id="10">-->
            <!--            <i class="el-icon-mobile-phone"></i>-->
            <!--            <span slot="title">移动端</span>-->
            <!--          </el-menu-item>-->

            <!--首页-->
            <el-menu-item index="/welcome" id="1">
              <i class="el-icon-menu"></i>
              <span slot="title">首页</span>
            </el-menu-item>

            <!--财务管理-->
            <el-submenu index="2" v-has="''">
              <!--   一级菜单模板区域   -->
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-coin"></i>
                <!--     文本  -->
                <span>财务管理</span>
              </template>
              <!--    二级菜单  -->
              <el-menu-item index="/user" id="1" v-has="''">
                <template slot="title">
                  <!--    图标    -->
                  <i class="el-icon-menu"></i>
                  <!--     文本  -->
                  <span>提现</span>
                </template>
              </el-menu-item>
              <!--   二级菜单  -->
              <el-menu-item index="/roles" id="2" v-has="''" >
                <template slot="title">
                  <!--    图标    -->
                  <i class="el-icon-menu"></i>
                  <!--     文本  -->
                  <span>提现记录</span>
                </template>
              </el-menu-item>
              <!--   二级菜单  -->
              <el-menu-item index="/platform" id="3" v-has="''">
                <template slot="title">
                  <!--    图标    -->
                  <i class="el-icon-menu"></i>
                  <!--     文本  -->
                  <span>提现管理</span>
                </template>
              </el-menu-item>

              <!--            <el-menu-item index="/project" id="4" >-->
              <!--              <template slot="title">-->
              <!--                &lt;!&ndash;    图标    &ndash;&gt;-->
              <!--                <i class="el-icon-menu"></i>-->
              <!--                &lt;!&ndash;     文本  &ndash;&gt;-->
              <!--                <span>项目管理</span>-->
              <!--              </template>-->
              <!--            </el-menu-item>-->

            </el-submenu>

            <!--平台管理-->
            <el-submenu index="3" v-has="''">
              <!--   一级菜单模板区域   -->
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-c-scale-to-original"></i>
                <!--     文本  -->
                <span>平台管理</span>
              </template>


              <!--   二级菜单  -->
              <el-menu-item index="/platform" id="2" v-has="'GET_PingtaiPermission'">
                <template slot="title">
                  <!--    图标    -->
                  <i class="el-icon-menu"></i>
                  <!--     文本  -->
                  <span>平台管理</span>
                </template>
              </el-menu-item>

              <el-menu-item index="/project" id="3" v-has="'GET_XiangmuPermission'">
                <template slot="title">
                  <!--    图标    -->
                  <i class="el-icon-menu"></i>
                  <!--     文本  -->
                  <span>项目管理</span>
                </template>
              </el-menu-item>

              <!--   二级菜单  -->
              <el-menu-item index="/roles" id="4" v-has="'GET_UserPermission'">
                <template slot="title">
                  <!--    图标    -->
                  <i class="el-icon-menu"></i>
                  <!--     文本  -->
                  <span>角色管理</span>
                </template>
              </el-menu-item>

            </el-submenu>

            <!--用户管理-->
            <el-submenu index="4" v-has="''">
              <!--   一级菜单模板区域   -->
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-user-solid"></i>
                <!--     文本  -->
                <span>用户管理</span>
              </template>

              <!--    二级菜单  -->
              <el-menu-item index="/AdminUser" id="1" v-has="'GET_UserPermission'">
                <template slot="title">
                  <!--    图标    -->
                  <i class="el-icon-menu"></i>
                  <!--     文本  -->
                  <span>管理员管理</span>
                </template>
              </el-menu-item>
              <!--    二级菜单  -->
              <el-menu-item index="/user" id="4" v-has="'GET_UserPermission'">
                <template slot="title">
                  <!--    图标    -->
                  <i class="el-icon-menu"></i>
                  <!--     文本  -->
                  <span>客户管理</span>
                </template>
              </el-menu-item>

            </el-submenu>

            <!--订单管理-->
            <el-submenu index="5" v-has="''">
              <!--   一级菜单模板区域   -->
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-document"></i>
                <!--     文本  -->
                <span>订单管理</span>
              </template>
              <!--    二级菜单  -->
              <el-menu-item index="/paymentOrder" id="1" v-has="'GET_PayorderPermission'">
                <template slot="title">
                  <!--    图标    -->
                  <i class="el-icon-menu"></i>
                  <!--     文本  -->
                  <span>支付订单</span>
                </template>
              </el-menu-item>
              <!--    二级菜单  -->
              <el-menu-item index="/paymentRefund" id="3" v-has="'GET_PayorderPermission'">
                <template slot="title">
                  <!--    图标    -->
                  <i class="el-icon-menu"></i>
                  <!--     文本  -->
                  <span>支付退款</span>
                </template>
              </el-menu-item>


              <!--            &lt;!&ndash;    二级菜单  &ndash;&gt;-->
              <!--            <el-menu-item index="/rechargeOrder" id="2" v-has="'GET_ChongzhiOrderPermission'">-->
              <!--              <template slot="title">-->
              <!--                &lt;!&ndash;    图标    &ndash;&gt;-->
              <!--                <i class="el-icon-menu"></i>-->
              <!--                &lt;!&ndash;     文本  &ndash;&gt;-->
              <!--                <span>充值订单</span>-->
              <!--              </template>-->
              <!--            </el-menu-item>-->
              <!--            &lt;!&ndash;    二级菜单  &ndash;&gt;-->
              <!--            <el-menu-item index="/rechargeRefund" id="4" v-has="'GET_ChongzhiOrderPermission'">-->
              <!--              <template slot="title">-->
              <!--                &lt;!&ndash;    图标    &ndash;&gt;-->
              <!--                <i class="el-icon-menu"></i>-->
              <!--                &lt;!&ndash;     文本  &ndash;&gt;-->
              <!--                <span>充值退款</span>-->
              <!--              </template>-->
              <!--            </el-menu-item>-->

            </el-submenu>

            <!--活动管理-->
            <el-submenu index="6" v-has="''">
              <!--   一级菜单模板区域   -->
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-star-off"></i>
                <!--     文本  -->
                <span>活动管理</span>
              </template>
              <!--    二级菜单  -->
              <el-menu-item index="/activity" id="5" v-has="'GET_HuodongPermission'">
                <template slot="title">
                  <!--    图标    -->
                  <i class="el-icon-menu"></i>
                  <!--     文本  -->
                  <span>活动管理</span>
                </template>
              </el-menu-item>

            </el-submenu>

            <!--吹风机管理-->
            <el-submenu index="7" v-has="''" >
              <!--   一级菜单模板区域   -->
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-potato-strips"></i>
                <!--     文本  -->
                <span>吹风机管理</span>
              </template>
              <!--    二级菜单  -->
              <el-menu-item index="/blowing" id="3" v-has="'GET_ChuifengjiPermission'">
                <template slot="title">
                  <!--    图标    -->
                  <i class="el-icon-menu"></i>
                  <!--     文本  -->
                  <span>吹风机列表</span>
                </template>
              </el-menu-item>

              <!--    二级菜单  -->
              <el-menu-item index="/blowServe" id="4" v-has="'GET_ChuifengjifuwuPermission'">
                <template slot="title">
                  <!--    图标    -->
                  <i class="el-icon-menu"></i>
                  <!--     文本  -->
                  <span>吹风机服务</span>
                </template>
              </el-menu-item>


            </el-submenu>

            <!--充电桩管理-->
            <el-submenu index="8" v-has="''" >
              <!--   一级菜单模板区域   -->
              <template slot="title">
                <!--    图标    -->
                <i class="el-icon-potato-strips"></i>
                <!--     文本  -->
                <span>充电桩管理</span>
              </template>
              <!--    二级菜单  -->
              <el-menu-item index="/piles" id="3" v-has="'GET_ChuifengjiPermission'">
                <template slot="title">
                  <!--    图标    -->
                  <i class="el-icon-menu"></i>
                  <!--     文本  -->
                  <span>充电桩列表</span>
                </template>
              </el-menu-item>

              <!--    二级菜单  -->
              <el-menu-item index="/pilesServe" id="4" v-has="'GET_ChuifengjifuwuPermission'">
                <template slot="title">
                  <!--    图标    -->
                  <i class="el-icon-menu"></i>
                  <!--     文本  -->
                  <span>充电桩服务</span>
                </template>
              </el-menu-item>


            </el-submenu>


            <!--          <el-submenu index="9" v-has="''">-->
            <!--            &lt;!&ndash;   一级菜单模板区域   &ndash;&gt;-->
            <!--            <template slot="title">-->
            <!--              &lt;!&ndash;    图标    &ndash;&gt;-->
            <!--              <i class="el-icon-money"></i>-->
            <!--              &lt;!&ndash;     文本  &ndash;&gt;-->
            <!--              <span>洗衣机管理</span>-->
            <!--            </template>-->
            <!--            &lt;!&ndash;    二级菜单  &ndash;&gt;-->
            <!--            <el-menu-item index="/washing" id="3" v-has="'GET_UserPermission'">-->
            <!--              <template slot="title">-->
            <!--                &lt;!&ndash;    图标    &ndash;&gt;-->
            <!--                <i class="el-icon-menu"></i>-->
            <!--                &lt;!&ndash;     文本  &ndash;&gt;-->
            <!--                <span>洗衣机列表</span>-->
            <!--              </template>-->
            <!--            </el-menu-item>-->

            <!--            &lt;!&ndash;    二级菜单  &ndash;&gt;-->
            <!--            <el-menu-item index="/washServe" id="4" v-has="'GET_UserPermission'">-->
            <!--              <template slot="title">-->
            <!--                &lt;!&ndash;    图标    &ndash;&gt;-->
            <!--                <i class="el-icon-menu"></i>-->
            <!--                &lt;!&ndash;     文本  &ndash;&gt;-->
            <!--                <span>洗衣机服务</span>-->
            <!--              </template>-->
            <!--            </el-menu-item>-->
            <!--          </el-submenu>-->





          </el-menu>

        </el-drawer>

        <!-- 主体内容（路由占位符） -->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>


</template>

<script>

import axios from "axios";

import 'element-ui/lib/theme-chalk/display.css';

export default {
  name: "Home",
  components: {

  },
  data () {
    return{

      drawer: false,
      direction: 'ltr',

      value:'',

      selectData:'',
      username: '',
      ptWeizhi:'',

      xmList:[],
      Total:0,
      newPage1:0,

      isCollapse:false,
    }
  },
  created() {
      this.getData()

  },
  methods: {

    //获取到用户名的缓存
   async getData(page) {
      const user = window.sessionStorage.getItem('username');
      this.username=user;

      if(page == undefined) {
        page = 1
      }
      await axios({

        method: "get",
        url: "/iotplatform/xiangmu/",
        //请求设备列表参数对象
        // data: this.DevicesListInfo,
        params: { page: page, },
      }).then((res) => {
        console.log(res);


        //获取请求后的数据重新赋值
        const DeviceInfo = (res.data.result.results);

        const total = (res.data.result.count);
        // //将请求的数据赋值给data中数据
        this.xmList = DeviceInfo;
        this.Total = total

        //
        //
        console.log(DeviceInfo)
        //若status不是200,返回错误消息
        if(res.status !== 200) {
          return this.$message.error('获取失败！')
        }
      });
    },

    //下拉平台选中函数
    selectChange(value) {
      console.log(value);

       //获取缓存（项目）
       window.sessionStorage.setItem('xiangmuId',value);
      //  let xm = window.sessionStorage.getItem('xiangmuId');
      //  this.value = xm
      //
      //  console.log(xm);
      // console.log(this.value);

    },



    //监听 pageSize 改变事件
    handleSizeChange1(newSize) {
      console.log(newSize);
      this.PageSize = newSize;
    },

    //监听 页码值 改变事件 ,并发起请求
    async handleCurrentChange1(newPage1) {

      console.log(newPage1)



      await this.getData(newPage1)
    },



    //头部下拉菜单1功能
    dropdown1(command) {
      if (command == 'logout') {
        //清空token
        window.sessionStorage.clear()
        //跳转到login
        this.$router.push('/login')
      }else  if (command == 'switch') {
        //跳转到,切换角色
      }else  if (command == 'revise') {
        //跳转到,修改密码
      }else  if (command == 'message') {
        //跳转到,消息中心
      }

    },

    //头部下拉菜单2功能
    dropdown2(command) {
      if (command == 'logout') {
        //清空token
        window.sessionStorage.clear()
        //跳转到login
        this.$router.push('/login')
      }else  if (command == 'switch') {
        //跳转到,切换角色
      }else  if (command == 'revise') {
        //跳转到,修改密码
        this.$router.push('/register')
      }else  if (command == 'message') {
        //跳转到,消息中心
      }

    },

    //点击按钮，控制菜单，展开折叠
    toggleCollapse() {
      this.isCollapse = !this.isCollapse
    },

  },


}
</script>

<style lang="less" scoped>
.home-container {

  height: 100%;
}

.topBox {
  width: 100%;
  height: 60px;
}


.el-header {
  background-color: #3979D8;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #ffffff;
  font-size: 22px;
  > div {
    display: flex;
    align-items: center;
    span {
      margin-left: 50px;
    }
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #ffffff;
}
.el-icon-arrow-down {
  font-size: 12px;
}

.el-aside {
  background-color: #333744;
}
.el-main {
  background-color: #EAEDF1;
}

.el-menu {
  border-right: none;
}

.toggle-button {
  background: #4A5064;
  color: #ffffff;
  font-size: 10px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.3em;
  cursor: pointer;
}

/deep/.el-drawer.ltr {
  background: #333744;
}
</style>
